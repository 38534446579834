import React, { Component } from 'react';

class AgendaContainer extends Component {

	render() {
		return ( 
            <div>
                <p>Agenda helper to go here.</p>
            </div>
		);
	}
}

export default AgendaContainer;